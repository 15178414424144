<template>
    <div class="sup_content language-list">
        <div class="sup_page_heading">
            <h2>Languages</h2>
            <Button icon="pi pi-plus" class="p-button-sm p-button-rounded p-button-primary" v-tooltip="'Add New Language'" @click="languageModals.addLanguage = true" />
        </div>
        <div class="sup_inner_content">
            <!--- Show Language list Start --->
            <DataTable class="p-datatable" :paginator="true" :value="allLanguageData.data" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {currentPage} of {totalPages}" :rowsPerPageOptions="[10,20,50]" :rowHover="true" :loading="allLanguageData.loading" responsiveLayout="scroll" removableSort :filters="filters">
                <template #loading>
                    Loading languages data. Please wait.
                </template>
                <template #header>
                    <div class="p-d-flex p-jc-between">
                        <div class="sup_list_action">
                            <Button :label="allLanguageData.activityStatus === 'active' ? 'Deactive List': 'Active List'" :icon="['pi', allLanguageData.activityStatus === 'active' ? 'pi-ban' : 'pi-check-circle']" :class="[allLanguageData.activityStatus === 'active' ? 'p-button-danger' : 'p-button-primary', 'p-mr-2']" @click="fetchAllLanguages(allLanguageData.activityStatus, 'languageStatus')" />
                        </div>
                        <div class="p-d-flex p-align-center">
                            <span class="p-input-icon-left sup_search_box">
                                <i class="pi pi-search" />
                                <InputText v-model="allLanguageData.search" placeholder="Search Language" @keyup.enter="fetchAllLanguages(allLanguageData.activityStatus, 'search')"/>
                            </span>
                            <Calendar v-model="allLanguageData.dateRange" placeholder="DD.MM.YY" dateFormat="dd.mm.yy" selectionMode="range" class="sup_button p-ml-2" inputClass="p-inputtext" :manualInput="false" :showIcon="true" @date-select="allLanguageData.dateRange[1] != null ? fetchAllLanguages(allLanguageData.activityStatus, 'dateRange') : ''" plceholder="abc" />
                            <Button v-if="allLanguageData.search !== null || allLanguageData.dateRange !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchAllLanguages(allLanguageData.activityStatus, 'resetFilters')"/>
                        </div>
                    </div>
                </template>
                <template #empty>
                    No Language found...
                </template>
                <Column field="languageName" header="Name" :sortable="true">
                    <template #body="slotProps">
                        <div class="sup_language_info">
                            <img v-if="slotProps.data.flag" :src="slotProps.data.flag" :alt="slotProps.data.languageName">
                            <strong>{{slotProps.data.languageName}}</strong>
                        </div>
                    </template>
                </Column>

                <Column field="voiceSupport" header="Voice Support" :sortable="true">
                    <template #body="slotProps">
                    {{slotProps.data.voiceSupport === null ? '--' : slotProps.data.voiceSupport}}
                    </template>
                </Column>

                <Column field="createdAt" header="Created On">
                    <template #body="slotProps">
                    {{usFormatDate(slotProps.data.createdAt)}}
                    </template>
                </Column>

                <Column field="status" header="Status" headerStyle="8rem">
                    <template #body="slotProps">
                        <span :class="['sup_item_status ', slotProps.data.status ? 'sup_item_status_success' : 'sup_item_status_danger']"><i :class="['pi ', slotProps.data.status ? 'pi-check' : 'pi-times']"></i>{{slotProps.data.status ? 'Active' : 'In-Active'}}</span>
                    </template>
                </Column>

                <Column field="action" header="Actions">
                    <template #body="slotProps">
                        <Button v-if="slotProps.data.deletedAt === null" type="button" icon="pi pi-pencil" class="p-button-sm p-button-rounded p-button-primary p-mr-1" @click="editLanguage(slotProps.data)" v-tooltip="'Edit Language'" />
                        <Button v-if="slotProps.data.deletedAt === null" type="button" icon="pi pi-ban" class="p-button-sm p-button-rounded p-button-danger p-mr-1" v-tooltip="'Deactivate'" @click="confirmationModalfn(slotProps.data, 'delete')" />
                        <Button v-if="slotProps.data.deletedAt !== null" icon="pi pi-undo" class="p-button-secondary p-button-rounded p-button-sm p-mr-1"  v-tooltip="'Restore Language'" @click="confirmationModalfn(slotProps.data, 'restore')" />
                        <Button v-if="slotProps.data.deletedAt !== null" icon="pi pi-trash" class="p-button-danger p-button-rounded p-button-sm"  v-tooltip="'Delete Language'" />
                    </template>
                </Column>
            </DataTable>
            <!--- Show Language list End --->
        </div>
        <!--- Add Language Modal Start --->
        <Dialog header="Add New Language" v-model:visible="languageModals.addLanguage" :modal="true" :style="{width: '600px'}">
            <form class="sup_language_add" @submit.prevent="createLanguage()">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="l_name">Name*</label>
                        <InputText v-model="addEditLanguage.name" id="l_name" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.nameEmpty">Name is not available.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="l_code">Language Code*</label>
                        <Dropdown v-model="addEditLanguage.code" :options="addEditDropdownData.languageCode" :filter="true" id="u_state" optionLabel="name" optionValue="code" placeholder="Eg: EN" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.codeEmpty">Selact a Language Code.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="l_voice">Voice Support*</label>
                        <Dropdown v-model="addEditLanguage.voiceSupport" :options="addEditDropdownData.voiceSupport" :filter="true" id="l_voice" optionLabel="name" optionValue="name" placeholder="Select a Voice" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.voiceSupportEmpty">Selact a Voice Support.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="u_state">Flag*</label>
                        <Dropdown v-model="addEditLanguage.countryFlag" :options="addEditDropdownData.languageFlag" :filter="true" id="u_state" optionLabel="name" placeholder="Select a Flag">
                            <template #value="slotProps">
                                <div class="sup_country_flag sup_country_flag_value p-d-flex p-align-center" v-if="slotProps.value">
                                    <img :src="slotProps.value.name" />
                                    <span class="p-ml-2">{{capitalizeCase(slotProps.value.code)}}</span>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div class="sup_country_flags p-d-flex p-align-center">
                                    <img :src="slotProps.option.name" />
                                    <span class="p-ml-2">{{capitalizeCase(slotProps.option.code)}}</span>
                                </div>
                            </template>
                        </Dropdown>
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.flagEmpty">Selact a Flag.</small>
                    </div>
                </div>
                <div class="p-fluid p-d-flex sup_submit_wrap">
                    <Button type="submit" label="Add" class="btn-primary sup_submit" />
                </div>
                <div :class="['sup_form_massage p-mt-3', addEditLanguage.addStatus === 200 ? 'success' : 'error']" v-if="addEditLanguage.addStatus !== ''">{{addEditLanguage.addMessage}}</div >
            </form>
        </Dialog>
        <!--- Add Language Modal End --->
        <!--- Edit Language Modal Start --->
        <Dialog header="Edit Language" v-model:visible="languageModals.editLanguage" :modal="true" :style="{width: '600px'}" @hide="resetForm()">
            <form class="sup_language_edit" @submit.prevent="updateLanguage(languageModals.editData)">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="l_name">Name*</label>
                        <InputText v-model="addEditLanguage.name" id="l_name" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.nameEmpty">Name is not available.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="l_code">Code*</label>
                        <Dropdown v-model="addEditLanguage.code" :options="addEditDropdownData.languageCode" :filter="true" id="u_state" optionLabel="name" optionValue="code" placeholder="Eg: EN" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.codeEmpty">Selact a Language Code.</small>
                    </div>
                </div>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-6">
                        <label for="l_voice">Voice Support*</label>
                        <Dropdown v-model="addEditLanguage.voiceSupport" :options="addEditDropdownData.voiceSupport" :filter="true" id="l_voice" optionLabel="name" optionValue="name" placeholder="Select a Voice" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.voiceSupportEmpty">Selact a Voice Support.</small>
                    </div>
                    <div class="p-field p-col-6">
                        <label for="u_state">Flag*</label>
                        <Dropdown v-model="addEditLanguage.countryFlag" :options="addEditDropdownData.languageFlag" :filter="true" id="u_state" optionLabel="name" placeholder="Select a Flag">
                            <template #value="slotProps">
                                <div class="sup_country_flag sup_country_flag_value p-d-flex p-align-center" v-if="slotProps.value">
                                    <img :src="slotProps.value.name" />
                                    <span class="p-ml-2">{{capitalizeCase(slotProps.value.code)}}</span>
                                </div>
                                <span v-else>
                                    {{slotProps.placeholder}}
                                </span>
                            </template>
                            <template #option="slotProps">
                                <div class="sup_country_flags p-d-flex p-align-center">
                                    <img :src="slotProps.option.name" />
                                    <span class="p-ml-2">{{capitalizeCase(slotProps.option.code)}}</span>
                                </div>
                            </template>
                        </Dropdown>
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.flagEmpty">Selact a Flag.</small>
                    </div>
                </div>
                <div class="p-fluid p-d-flex sup_submit_wrap">
                    <Button type="submit" label="Update" class="btn-primary sup_submit" />
                </div>
                <div :class="['sup_form_massage p-mt-3', addEditLanguage.editStatus === 200 ? 'success' : 'error']" v-if="addEditLanguage.editStatus !== ''">Update {{addEditLanguage.editMessage}}</div >
            </form>
        </Dialog>
        <!--- Edit Language Modal End --->
        <!--- Deactive/Restore Confirmation Start --->
        <Dialog header="Confirmation" v-model:visible="confirmationModal.modalState" :style="{width: '350px'}" :modal="true">
            <div class="confirmation-content p-d-flex p-items-center">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 1.6rem" />
                <span>Do you want to proceed?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="confirmationModal.modalState = false" class="p-button-text" autofocus/>
                <Button label="Yes" icon="pi pi-check" @click="deleteAndRestoreLanguage(confirmationModal.modaldata.data, confirmationModal.modaldata.type), confirmationModal.modalState = false" class="p-button-primary" />
            </template>
        </Dialog>
        <!--- Deactive/Restore Confirmation End --->
    </div>
</template>

<script>
import { onBeforeMount, reactive } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import { allLanguageList, allLanguageFlags, allLanguageVoice, allLanguageCode, languageById, allLanguageKeyList, allLanguageTextList } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import userLogout from '../../../../helper/userLogout/userLogout';
import { usFormatDate, capitalizeCase, formatDate } from "../../../../helper/utility/utility";

export default {
    name: "Languages",
    setup(){
        //Store call
        let store = useStore();

        //Fetch and filtring data initial state
        const allLanguageData = reactive({
            data: null,
            total: null,
            status: null,
            loading: false,
            activityStatus: 'active',
            search: null,
            dateRange: null
        });

        //Langage modals open-close state 
        const languageModals = reactive ({
            addLanguage: false,
            editLanguage: false,
            editData: null
        });

        //All from input and message data binding state
        const addEditLanguage = reactive ({
            name: '',
            code: '',
            voiceSupport: '',
            countryFlag: '',
            addStatus: '',
            addMessage: '',
            editStatus: '',
            editMessage: ''
        });

        //All from error handling state
        const addEditvalidationError = reactive ({
            nameEmpty: false,
            codeEmpty: false,
            voiceSupportEmpty: false,
            flagEmpty: false
        });

        //Add-edit form dropdown list data initial state
        const addEditDropdownData = reactive({
            voiceSupport: [],
            languageCode: null,
            languageFlag: []
        });

        //Active-deactive confimation state
        const confirmationModal = reactive({
            modalState: false,
            modaldata: '',
        });

        //Before component mount required function invoke
        onBeforeMount(async () => {
            fetchAllLanguages();
            fetchLanguageFlags();
            fetchLanguageVoice();
            fetchLanguageCode();
        });

        //All Langage data fetching
        const fetchAllLanguages = async (status, activity) => {
            let date = null;
            if(activity === 'languageStatus'){
                allLanguageData.activityStatus = status === 'active' ? 'de-active' : 'active' ;
            }

            if(activity === 'resetFilters'){
                allLanguageData.search = null;
                allLanguageData.dateRange = null;
            }

            if (activity === 'dateRange') {
                date = formatDate(allLanguageData.dateRange[0]) + ' ' + formatDate(allLanguageData.dateRange[1]);
            }

            try{
                allLanguageData.loading = !allLanguageData.loading;
                const response = await axios.get(allLanguageList, {
                    params: {
                        status: allLanguageData.activityStatus,
                        search: allLanguageData.search,
                        filterDt: date
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                })
                allLanguageData.data = response.data.data.language;
                allLanguageData.total = response.data.data.total;
                allLanguageData.loading = !allLanguageData.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        //Langage Flags data list fetching
        const fetchLanguageFlags = async() => {
            try{

                const response = await axios.get(allLanguageFlags, {
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                //console.log("flags ->", response.data);
                for (let i = 0; i < response.data.data.length; i++) {
                    addEditDropdownData.languageFlag.push({
                        name: response.data.data[i],
                        code: response.data.data[i].split('/')[3]
                    })
                }

            } catch(err){
                console.log(err);
            }
        }

        //Langage Voice support data list fetching
        const fetchLanguageVoice = async() => {
            try{

                const response = await axios.get(allLanguageVoice, {
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                for (let i = 0; i < response.data.data.length; i++) {
                    addEditDropdownData.voiceSupport.push({
                        name:response.data.data[i]
                    })
                }

            } catch(err){
                addEditDropdownData.voiceSupport = null;
            }
        }

        //Langage code data list fetching
        const fetchLanguageCode = async() => {
            try{

                const response = await axios.get(allLanguageCode, {
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                addEditDropdownData.languageCode = response.data.data;
                
            } catch(err){
                addEditDropdownData.languageCode = null;
            }
        }

        //New langauge create HTTP call
        const createLanguage = async() => {
            let isValidate = languageFormValidation();

            if(isValidate){
                try{
                    const response = await axios.post(allLanguageList, {
                        languageName: addEditLanguage.name,
                        languageCode: addEditLanguage.code,
                        countryFlag: addEditLanguage.countryFlag.code,
                        voiceSupport: addEditLanguage.voiceSupport
                    },{
                        headers:  {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken
                        }
                    });
                    addEditLanguage.addStatus = response.data.status;
                    addEditLanguage.addMessage = response.data.message;
                    resetForm();
                    fetchAllLanguages();
                    translateSync()
                } catch(err){
                    console.log(err);
                }
            }
        }

        //Add-edit language form validation
        const languageFormValidation = () => {
            if(addEditLanguage.name === ""){
                addEditvalidationError.nameEmpty = true;
            } else {
                addEditvalidationError.nameEmpty = false;
            }

            if(addEditLanguage.code === ""){
                addEditvalidationError.codeEmpty = true;
            } else {
                addEditvalidationError.codeEmpty = false;
            }

            if(addEditLanguage.voiceSupport === ""){
                addEditvalidationError.voiceSupportEmpty = true;
            } else {
                addEditvalidationError.voiceSupportEmpty = false;
            }

            if(addEditLanguage.countryFlag === ""){
                addEditvalidationError.flagEmpty = true;
            } else {
                addEditvalidationError.flagEmpty = false;
            }

            if(addEditvalidationError.nameEmpty && addEditvalidationError.codeEmpty && addEditvalidationError.voiceSupportEmpty && addEditvalidationError.flagEmpty) {
                return false;
            } else {
                return true;
            }
        }

        //Reset form
        const resetForm = () => {
            addEditLanguage.name = '';
            addEditLanguage.code = '';
            addEditLanguage.voiceSupport = '';
            addEditLanguage.countryFlag = '';
            addEditLanguage.addStatus= '';
            addEditLanguage.addMessage= '';
            addEditLanguage.editStatus= '';
            addEditLanguage.editMessage= '';
        }

        //Delete-restore language confirmation modal
        const confirmationModalfn = (sloteData, load) => {
            confirmationModal.modalState = true;
            confirmationModal.modaldata = {
                data: sloteData,
                type: load
            }
        }

        //Delete-restore language HTTP call
        const deleteAndRestoreLanguage = async (slotData, methodType) => {
            try{
                const options = {
                    method: methodType === 'delete' ? 'delete' : 'patch',
                    url: allLanguageList,
                    params: {
                        uuid: slotData.uuid
                    },
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                await axios(options);
                fetchAllLanguages('active');
            } catch(err){
                console.log(err);
            }
        }

        //Specific language data fill up and edit popup one
        const editLanguage = async(slotData) => {
            const response = await axios.get( languageById, {
                params: {
                    uuid: slotData.uuid,
                }, 
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                },
            });

            if(response.data.status === 200 ){
                languageModals.editLanguage = true;
                languageModals.editData = slotData.uuid
                addEditLanguage.name = response.data.data.languageName;
                addEditLanguage.voiceSupport = response.data.data.voiceSupport;
                addEditLanguage.code = response.data.data.languageCode;
                if(response.data.data.flag){
                    addEditLanguage.countryFlag = {
                        code: response.data.data.flag.split("-")[4],
                        name: "https://www.countryflags.io/" + response.data.data.flag.split("-")[4] + "/flat/32.png"
                    };
                }
            }
        }

        //langage update HTTP call
        const updateLanguage = async(uuid) => {
            try{
                const options = {
                    method: 'put',
                    url: allLanguageList,
                    params: {
                        uuid: uuid
                    },
                    data: JSON.stringify({
                        languageName: addEditLanguage.name,
                        languageCode: addEditLanguage.code,
                        countryFlag: addEditLanguage.countryFlag.code,
                    }),
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                let response = await axios(options);
                addEditLanguage.editStatus = response.data.status;
                addEditLanguage.editMessage = response.data.message;
                fetchAllLanguages('active');
            } catch(err){
                console.log('err', err);
                // allCustomerData.editStatus = err.response.data.status;
                // allCustomerData.editMessage = err.response.data.message;
            }
        }

        //Translate Sync
        const translateSync = async() =>{
            let uuid = [];
            const response = await axios.get(allLanguageKeyList, {
                headers: {
                    apiKey: apiKey,
                    token: store.getters.adminAuthToken
                }
            });

            if(response.data.status === 200){
                for(let i=0; i< response.data.data.languageKey.length; i++){
                    uuid.push(response.data.data.languageKey[i].uuid)
                }
                await axios.post(allLanguageTextList,{
                    text: uuid
                },{
                    headers:  {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
            }
        }

        return {
            allLanguageData,
            addEditLanguage,
            languageModals,
            addEditvalidationError,
            addEditDropdownData,
            confirmationModal,
            fetchAllLanguages,
            usFormatDate,
            createLanguage,
            capitalizeCase,
            confirmationModalfn,
            deleteAndRestoreLanguage,
            editLanguage,
            updateLanguage,
            resetForm
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/style/component/superAdmin/pages/widgetManagement/Languages'
</style>